<template>
  <svg :width="width" :height="height" :viewBox="viewBox" fill="none">
    <g :fill="color">
      <path
        d="M11.7188 4.22315C7.52441 4.47218 3.6377 6.84034 1.05469 10.7271C0.800781 11.1079 0.458984 11.6694 0.297852 11.9673L0 12.5191L0.297852 13.0659C2.41211 16.9575 5.94238 19.7261 9.86328 20.5659C11.5039 20.9224 13.4961 20.9224 15.1367 20.5659C18.6279 19.8189 21.7627 17.5874 23.9453 14.311C24.1992 13.9302 24.541 13.3687 24.7021 13.0659L25 12.5191L24.7021 11.9673C23.1836 9.17921 20.8545 6.8794 18.2227 5.56593C16.1572 4.53565 13.9893 4.08643 11.7188 4.22315ZM13.501 7.42139C15.0781 7.72413 16.4258 8.74952 17.1484 10.1997C17.5244 10.9566 17.6855 11.645 17.6855 12.5191C17.6855 13.896 17.2119 15.1021 16.2842 16.0786C15.2637 17.1528 13.9844 17.7046 12.5 17.7046C9.98535 17.7046 7.91992 16.0103 7.39746 13.52C7.29004 13.0024 7.29004 12.0357 7.39746 11.5181C7.6123 10.5024 8.03711 9.67725 8.71582 8.95948C9.14551 8.50538 9.51172 8.22218 10.0244 7.95362C11.1475 7.35792 12.2607 7.18702 13.501 7.42139Z"
      />
      <path
        d="M11.8213 9.44824C10.9521 9.63867 10.1221 10.2881 9.72656 11.0889C9.2627 12.0312 9.2627 12.9687 9.72656 13.9111C10.127 14.7217 10.9521 15.3613 11.8457 15.5518C14.0479 16.0205 16.0205 14.0478 15.5518 11.8457C15.2002 10.1758 13.501 9.08691 11.8213 9.44824Z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    viewBox: { type: String, default: "0 0 25 25" },
    width: { type: String, default: "20" },
    height: { type: String, default: "20" },
    color: { type: String, default: "#000" },
  },
};
</script>