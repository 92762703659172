<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
    fill="none"
  >
    <g :fill="color">
      <path
        d="M6.47045898 16.6666565c.5715332 0 .85559082.27947997.85559082.84890746.00024415.51187134-.00024414 1.0232544-.00073242 1.53442383-.0012207 1.32125855-.00231933 2.64123535.00793457 3.965271.00390625.45449829-.1225586.78881836-.54418945.98474121H.5435791c-.42163086-.19525146-.54736328-.53024292-.54345703-.98410034.01245117-1.33306885.01049805-2.6665039.00854492-3.99978638-.00073242-.49084473-.00146484-.98168945-.00146484-1.47247314 0-.60336304.27441406-.87698365.87976074-.87698365H6.470459zM1.4819336 22.51309203h4.36096192v-4.3699646H1.48193359v4.3699646z"
      />
      <path
        d="M17.31259518 3.18485312H11.77926582c-.00866905-.01793084-.0173381-.03524337-.02662636-.0531742.19319592-.18549142.39072637-.37098284.57896855-.56451222.31703946-.32399167.33313912-.74196566.04644133-1.03009567-.2866978-.28813-.7102427-.26958085-1.0316167.04884608-.58701837.58120644-1.17197269 1.1648861-1.75486295 1.75103897-.34242739.34439573-.34428504.73701923-.00495374 1.07956005.58206463.58615287 1.16660614 1.16983253 1.75362452 1.75103897.32385086.32090015.74306124.34192251 1.03161669.06183047.28855545-.28009204.27740953-.71228704-.05387194-1.04617159-.18576531-.18549141-.38267654-.37098283-.57525324-.55276442.0105267-.01793084.0210534-.03586167.03096088-.05379251h5.55438277c.80931753 0 1.2805422.46991159 1.28116142 1.27741756v12.82240333c0 .79204835-.47493998 1.26752468-1.26691942 1.26814299H10.08570541c-.31703946 0-.55729593.12984399-.68795086.41797399-.2167262.47423972.13808555.9701201.69476226.97073841h7.23246273c1.5851973 0 2.67440124-1.09254444 2.67502046-2.67973266V5.87818849c-.00061922-1.60511906-1.08672706-2.69209876-2.68740482-2.69333537z"
      />
      <path
        d="M6.52038574 7.3333435H.80383301C.2996826 7.33267213.00134277 7.03222657 0 6.52606202V.83404541C.00061035.29324341.289917 0 .82543945 0h5.6916504c.52111816.00064087.81628417.29650879.81628417.81903076v5.6920166c0 .52444458-.2939453.82229615-.81298828.82229615zM5.8503418 1.48587037H1.47973633v4.37008667H5.8503418V1.48587036z"
      />
      <path
        d="M.8203125 8h5.69274902c.52380371.00064087.82092286.29452515.8203125.81567383v5.71569824c-.00073242.5041504-.3017578.80197144-.80859375.80197144H.83337402C.2932129 15.3326721 0 15.04205322 0 14.50588989V8.8143921C0 8.29452515.29785156 8 .8203125 8zm.65966797 5.84890747h4.37597656V9.47921753H1.47998047v4.36968994z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    viewBox: { type: String, default: "0 0 20 24" },
    width: { type: String, default: "20" },
    height: { type: String, default: "24" },
    color: { type: String, default: "#000" },
  },
};
</script>
