<template>
  <svg :width="width" :height="height" :viewBox="viewBox" fill="none">
    <g :fill="color">
      <path
        d="M21.2208 3.80507H15.6104V3.0778C15.6104 2.5267 15.3915 1.99817 15.0018 1.60849C14.6121 1.2188 14.0836 0.999878 13.5325 0.999878H10.4675C9.91643 0.999878 9.38791 1.2188 8.99822 1.60849C8.60853 1.99817 8.38961 2.5267 8.38961 3.0778V3.80507H2.77922C2.57462 3.81157 2.38018 3.89576 2.23543 4.0405C2.09068 4.18525 2.0065 4.37969 2 4.58429C2 4.79096 2.0821 4.98915 2.22823 5.13529C2.37436 5.28142 2.57256 5.36351 2.77922 5.36351H4.15584V17.5194C4.15584 18.6216 4.59369 19.6786 5.37306 20.458C6.15243 21.2374 7.20949 21.6752 8.31169 21.6752H15.7143C16.261 21.6787 16.803 21.5735 17.3087 21.3659C17.8145 21.1583 18.274 20.8523 18.6606 20.4657C19.0472 20.0791 19.3532 19.6196 19.5608 19.1138C19.7684 18.608 19.8736 18.0661 19.8701 17.5194V5.36351H21.2208C21.4274 5.36351 21.6256 5.28142 21.7718 5.13529C21.9179 4.98915 22 4.79096 22 4.58429C21.9935 4.37969 21.9093 4.18525 21.7646 4.0405C21.6198 3.89576 21.4254 3.81157 21.2208 3.80507ZM9.94805 3.0778C9.94805 2.94003 10.0028 2.80789 10.1002 2.71047C10.1976 2.61305 10.3298 2.55832 10.4675 2.55832H13.5325C13.6702 2.55832 13.8024 2.61305 13.8998 2.71047C13.9972 2.80789 14.0519 2.94003 14.0519 3.0778V3.80507H9.94805V3.0778ZM18.3117 17.5194C18.3117 17.8605 18.2445 18.1982 18.114 18.5133C17.9834 18.8285 17.7921 19.1148 17.5509 19.356C17.3097 19.5972 17.0234 19.7885 16.7083 19.919C16.3931 20.0496 16.0554 20.1168 15.7143 20.1168H8.31169C7.97059 20.1168 7.63284 20.0496 7.31771 19.919C7.00257 19.7885 6.71624 19.5972 6.47505 19.356C6.23386 19.1148 6.04253 18.8285 5.912 18.5133C5.78147 18.1982 5.71429 17.8605 5.71429 17.5194V5.36351H18.3117V17.5194Z"
      />
      <path
        d="M8.44145 17.6492C8.64811 17.6492 8.84631 17.5671 8.99245 17.421C9.13858 17.2749 9.22067 17.0767 9.22067 16.87V8.03884C9.2243 7.93553 9.20663 7.83259 9.16875 7.73641C9.13088 7.64023 9.07361 7.55287 9.00052 7.47978C8.92742 7.40668 8.84007 7.34942 8.74389 7.31154C8.64771 7.27367 8.54476 7.25599 8.44145 7.25962C8.23479 7.25962 8.03659 7.34172 7.89046 7.48785C7.74433 7.63398 7.66223 7.83218 7.66223 8.03884V16.87C7.66873 17.0746 7.75291 17.2691 7.89766 17.4138C8.04241 17.5585 8.23685 17.6427 8.44145 17.6492Z"
      />
      <path
        d="M11.9998 17.6493C12.2065 17.6493 12.4047 17.5672 12.5508 17.421C12.6969 17.2749 12.779 17.0767 12.779 16.87V8.03886C12.779 7.8322 12.6969 7.634 12.5508 7.48787C12.4047 7.34174 12.2065 7.25964 11.9998 7.25964C11.7931 7.25964 11.5949 7.34174 11.4488 7.48787C11.3027 7.634 11.2206 7.8322 11.2206 8.03886V16.87C11.2206 17.0767 11.3027 17.2749 11.4488 17.421C11.5949 17.5672 11.7931 17.6493 11.9998 17.6493Z"
      />
      <path
        d="M15.5585 17.6492C15.7631 17.6427 15.9575 17.5585 16.1023 17.4138C16.247 17.2691 16.3312 17.0746 16.3377 16.87V8.03884C16.3377 7.83218 16.2556 7.63398 16.1095 7.48785C15.9634 7.34172 15.7652 7.25962 15.5585 7.25962C15.4552 7.25599 15.3522 7.27367 15.2561 7.31154C15.1599 7.34942 15.0725 7.40668 14.9994 7.47978C14.9263 7.55287 14.8691 7.64023 14.8312 7.73641C14.7933 7.83259 14.7756 7.93553 14.7793 8.03884V16.87C14.7793 17.0767 14.8614 17.2749 15.0075 17.421C15.1536 17.5671 15.3518 17.6492 15.5585 17.6492Z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    viewBox: { type: String, default: "1 0 23 23" },
    width: { type: String, default: "22" },
    height: { type: String, default: "22" },
    color: { type: String, default: "#4c4c4c" },
  },
};
</script>