<template>
  <ul class="horizontal-menu">
    <router-link
      v-for="(item, key) in tabs"
      :key="`${key}-item`"
      :to="{ path: item.path }"
      active-class="active"
      v-slot="{ isExactActive, navigate }"
      custom
    >
      <li
        class="horizontal-menu__item"
        :class="isExactActive ? 'horizontal-menu__item--active' : ''"
        @click="navigate"
      >
        {{ item.title }}
      </li>
    </router-link>
  </ul>
</template>
<script>
export default {
  props: {
    tabs: { type: Array },
  },
};
</script>
<style lang="scss" scoped>
.horizontal-menu {
  position: relative;
  display: flex;
  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4.2rem;
    border-radius: 20px;
    background-color: transparent;
    color: $black;
    @include type();
    text-align: center;
    padding: 0 2.5rem;
    margin-right: 10px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    &--active,
    &:hover {
      background-color: $orange;
      color: $white;
    }
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      font-size: 14px;
    }
  }
}
</style>