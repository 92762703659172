<template>
  <div class="table">
    <div
      class="table__inner"
      ref="table"
      @mousedown="
        isDown = true;
        mousedown($event);
      "
      @mouseleave="isDown = false"
      @mouseup="isDown = false"
      @mousemove="mousemove($event)"
    >
      <div class="table__content">
        <header class="table__header">
          <div
            class="table__header_col"
            :class="`table__header_col--${item.className}`"
            v-for="(item, key) in headerItems"
            :key="`header-${key}`"
          >
            <p class="text">{{ item.title }}</p>
            <span class="icons" v-if="item.sortable">
              <span
                class="icon"
                :class="{
                  'icon--active':
                    tableData && tableData.sort === item.key && tableData.order === 'asc',
                }"
                @click="item.sortable ? $emit('sortTable', { order: 'asc', sort: item.key }) : ''"
              >
                <IconArrowTop />
              </span>
              <span
                class="icon icon--down"
                :class="{
                  'icon--active':
                    tableData && tableData.sort === item.key && tableData.order === 'desc',
                }"
                @click="item.sortable ? $emit('sortTable', { order: 'desc', sort: item.key }) : ''"
              >
                <IconArrowTop />
              </span>
            </span>
          </div>
        </header>
        <div class="table__progress">
          <vue-progress-bar :thickness="thickness" />
        </div>
        <div class="table__body" id="infinite-scroll" @scroll="scrollTable('#infinite-scroll')">
          <template v-if="items.length">
            <div class="table__body_row" v-for="(item, key) in items" :key="`row-${key}`">
              <div
                class="table__body_col"
                :class="`table__body_col--${el.class}`"
                v-for="(el, index) in columnNames"
                :key="`header-${index}`"
              >
                <template v-if="!el.variables">
                  <p class="table__item">
                    {{ item[el.key] }}
                  </p></template
                >
                <template v-else>
                  <span
                    v-if="el.variables === 'icon'"
                    class="table__icon"
                    :class="[
                      `table__icon--${el.variables}`,
                      {
                        'table__icon--disabled':
                          el.key === 'reorder' ? item.disabledReorder : false,
                      },
                    ]"
                    @click="$emit('clickAction', { key: el.key, id: item.id, item: item })"
                  >
                    <component :is="el.icon"></component>
                  </span>
                  <div
                    v-if="el.variables === 'tag'"
                    class="table__item"
                    :class="`table__item--${el.variables}`"
                  >
                    <div :class="{ tag: item[el.key].main }">
                      <p>{{ item[el.key].main || "—" }}</p>
                    </div>
                    <AdditionalTags
                      v-if="item[el.key].additional.length"
                      :items="item[el.key].additional"
                      :parentClientRect="tableClientRect"
                      @getParentClientRect="getParentClientRect"
                    />
                  </div>
                  <p
                    v-if="el.variables === 'status'"
                    class="table__item"
                    :class="`table__item--${el.variables}`"
                  >
                    <span
                      class="table__item_circle"
                      :class="`table__item_circle--${item[el.key]}`"
                    ></span>
                    {{ item[el.key] }}
                  </p>
                </template>
              </div>
            </div>
          </template>
          <template v-else>
            <p class="table__body_empty">NO DATA</p>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { infiniteScroll } from "@/helper.js";
import IconArrowTop from "@/components/icons/IconArrowTop.vue";
import IconEdit from "@/components/icons/IconEdit.vue";
import IconDelete from "@/components/icons/IconDelete.vue";
import IconReorder from "@/components/icons/IconReorder.vue";
import IconEye from "@/components/icons/IconEye.vue";
import AdditionalTags from "@/components/AdditionalTags.vue";

export default {
  props: {
    loading: { type: Boolean, default: false },
    headerItems: Array,
    items: Array,
    tableData: Object,
    thickness: String,
    hasMorePages: Boolean,
  },
  components: {
    IconArrowTop,
    IconEdit,
    IconDelete,
    IconReorder,
    IconEye,
    AdditionalTags,
  },
  data() {
    return {
      columnNames: [],
      tableClientRect: { bottom: 0, right: 0 },
      isDown: false,
      busy: false,
    };
  },
  created() {
    const keyItems = [];
    this.headerItems.map(h => {
      if (h.key) {
        let el = {
          key: h.key,
          class: h.className,
          variables: h.variables ? h.variables : false,
          icon: h.icon,
        };
        keyItems.push(el);
      }
    });
    this.columnNames = keyItems;
  },
  mounted() {
    this.getParentClientRect();
  },
  watch: {
    items() {
      this.getParentClientRect();
    },
    loading(val) {
      if (val === true) {
        this.$Progress.start();
      } else {
        this.$Progress.finish();
      }
    },
  },
  methods: {
    scrollTable(el) {
      let scroll = infiniteScroll(el);
      if (scroll && this.hasMorePages) {
        this.$emit("loadMore");
      }
    },
    getParentClientRect() {
      this.tableClientRect = this.$refs.table.getBoundingClientRect();
    },
    mousedown(e) {
      let tableScrolled = this.$refs.table;
      this.startX = e.pageX - tableScrolled.offsetLeft;
      this.scrollLeft = tableScrolled.scrollLeft;
    },
    mousemove(e) {
      let tableScrolled = this.$refs.table;
      if (!this.isDown) return;
      e.preventDefault();
      const x = e.pageX - tableScrolled.offsetLeft;
      const walk = (x - this.startX) * 3; //scroll-fast
      tableScrolled.scrollLeft = this.scrollLeft - walk;
    },
  },
};
</script>
<style lang="scss" scoped>
.table {
  border: 1px solid rgba($color: $grey, $alpha: 0.3);
  border-radius: 16px;
  padding-bottom: 20px;
  background-color: $white;
  flex: 1;
  &__inner {
    height: 100%;
    display: flex;
    overflow: hidden;
    overflow-x: auto;
    position: relative;
    padding-bottom: 20px;
  }
  &__content {
    width: auto;
    position: relative;
    margin-bottom: 0;
    overflow: initial;
    padding: 0;
    flex: 1;
  }
  &__header,
  &__body {
    min-width: 100%;
    &_row {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: stretch;
      min-width: 100%;
      transition: all 0.2s;
      padding: 0 28px;
      &::after {
        display: block;
        content: "";
        position: absolute;
        bottom: 0;
        left: 2.8rem;
        height: 1px;
        width: calc(100% - 5.6rem);
        background-color: rgba($color: $grey, $alpha: 0.3);
      }
    }
    &_col {
      display: flex;
      align-items: center;
      position: relative;
      padding: 2.5px 4px;
      flex: 1;
      min-width: 164px;
      box-sizing: content-box;
      &--indent {
        flex: 1.5;
        @media only screen and (max-width: $md) {
          display: none;
        }
      }
      &--lg {
        min-width: 230px;
      }
      &--mdx {
        justify-content: center;
        min-width: 160px;
        max-width: 160px;
      }
      &--md {
        min-width: 152px;
      }
      &--sm {
        min-width: 124px;
      }
      &--xsm {
        justify-content: center;
        min-width: 54px;
        max-width: 54px;
      }
      &--capitalize {
        .table__item {
          text-transform: capitalize;
        }
      }
    }
  }
  &__header {
    display: flex;
    height: 6.3rem;
    padding: 0 32px 0 28px;
    border-bottom: 1px solid rgba($color: $grey, $alpha: 0.3);
    &_col {
      .text {
        @include type($fw: 500);
        color: rgba($color: $black, $alpha: 0.7);
        @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
          font-size: 14px;
        }
      }
      .icons {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 10px;
        .icon {
          display: inherit;
          margin: 2px 0;
          cursor: pointer;
          &--down {
            transform: rotate(180deg);
          }
          &::v-deep g {
            fill: rgba($color: $black, $alpha: 0.5);
          }
          &:hover,
          &--active {
            &::v-deep g {
              fill: rgba($color: $black, $alpha: 1);
            }
          }
        }
      }
    }
  }
  &__body {
    min-height: 400px;
    height: 400px;
    overflow: hidden;
    overflow-y: auto;
    &_row {
      height: 8.4rem;
    }
    &_empty {
      padding: 25px;
      @include type($fw: 600);
      text-align: center;
    }
  }
  &__progress {
    position: relative;
    .__cov-progress {
      z-index: 2;
    }
  }
  &__item {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    @include type($fw: 500);
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      font-size: 14px;
    }
    &--tag {
      display: flex;
      overflow: visible;
      p {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-clamp: 2;
        text-overflow: clip;
        overflow: hidden;
        font-size: 12px;
        font-weight: 600;
        line-height: 1.25;
        color: $blue;
      }
      .tag {
        padding: 6px 10px;
        margin-right: 10px;
        border-radius: 6px;
        background-color: $blueLight;
      }
    }
    &--status {
      position: relative;
      display: flex;
      align-items: center;
      text-transform: capitalize;
      .table__item_circle {
        display: inline-block;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        margin-right: 12px;
        &--inactive {
          background-color: $light3Grey;
        }
        &--draft {
          background-color: $orange;
        }
        &--active,
        &--published {
          background-color: $green;
        }
      }
    }
  }
  &__icon {
    cursor: pointer;
    &::v-deep g {
      fill: $black;
      transition: all 0.3s ease-in-out;
    }
    &:hover {
      &::v-deep g {
        fill: $orange;
      }
    }
    &--disabled {
      cursor: default;
      &::v-deep g {
        fill: $greyDark;
      }
      &:hover {
        &::v-deep g {
          fill: $greyDark;
        }
      }
    }
  }
}
</style>
