<template>
  <svg :width="width" :height="height" :viewBox="viewBox" fill="none">
    <g :fill="color">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.25 4.50001H6.49999V0.750002C6.49999 0.335999 6.16399 0 5.74999 0H5.25001C4.83601 0 4.50001 0.335999 4.50001 0.750002V4.50001H0.750002C0.335999 4.50001 0 4.83601 0 5.25001V5.74999C0 6.16399 0.335999 6.49999 0.750002 6.49999H4.50001V10.25C4.50001 10.664 4.83601 11 5.25001 11H5.74999C6.16399 11 6.49999 10.664 6.49999 10.25V6.49999H10.25C10.664 6.49999 11 6.16399 11 5.74999V5.25001C11 4.83601 10.664 4.50001 10.25 4.50001V4.50001Z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    viewBox: { type: String, default: "0 0 11 11" },
    width: { type: String, default: "11" },
    height: { type: String, default: "11" },
    color: { type: String, default: "#000" },
  },
};
</script>